import { foreachElement } from './foreachElement';

/**
 * Removes the target attribute from links, if we are in an iframe (for infopoint.kastner.at)
 */
(function() {
    if (window.self === window.top) {
        return;
    }
    
    const initialized = new WeakMap();
    
    const init = () => {   
        foreachElement('a[href]', (link) => {
            if (initialized.has(link)) {
                return;
            }
        
            initialized.set(link, true);
    
            link.removeAttribute('target');
        });
    };
    
    if ('loading' === document.readyState) {
        document.addEventListener('DOMContentLoaded', init);
    } else {
        init();
    }
})();
