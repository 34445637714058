import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['count', 'products', 'activeCount', 'input']

    productsTargetConnected(products) {
        this.countTarget.innerText = products.dataset.productCount;
    }

    onChange() {
        if (!this.hasInputTarget || !this.hasActiveCountTarget) {
            return;
        }

        let count = 0;

        this.inputTargets.forEach((input) => {
            if (input.checked) {
                count++;
            }
        });

        this.activeCountTarget.innerText = count;

        if (count <= 0) {
            this.activeCountTarget.classList.add('hide');
        } else {
            this.activeCountTarget.classList.remove('hide');
        }
    }
}
